import React, { Component } from 'react';

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">

        <div class="accordin reveal">

          <div class="glitch-wrapper">
            <div class="glitch" data-text="FAQ">FAQ</div>
          </div>

          <details >
            <summary>What is the mint price?</summary>
            <div class="faq__content">
              <p>0.01 ETH</p>
            </div>
          </details>
          <details>
            <summary>When is the mint date?</summary>
            <div class="faq__content">
              <p>To be announced – be sure to follow the Alpha Skier Club twitter for updates. Will mint Spring 2023, most likely end of March or April</p>

            </div>
          </details>
          <details>
            <summary>How many NFTs will there be?</summary>
            <div class="faq__content">
              <p>3000</p>

            </div>
          </details>

          <details>
            <summary>Will the NFTs be uploaded to IPFS?</summary>
            <div class="faq__content">
              <p>Yes, and in very high resolution (2000x2000 pixels)</p>

            </div>
          </details>

          <details>
            <summary>Do I own the copyright to use the image however I wish?</summary>
            <div class="faq__content">
              <p>YES</p>

            </div>
          </details>

          <details>
            <summary>What will the art look like?</summary>
            <div class="faq__content">
              <p>Follow the official Alpha Skier Club twitter where we will post sneak peaks, and also check out the "sneak peaks" section in the discord</p>

            </div>
          </details>
          <details>
            <summary>Are there also snowboarders in the collection?</summary>
            <div class="faq__content">
              <p>YES. It is mostly skiers but we do have snowboarders!</p>

            </div>
          </details>
          <details>
            <summary>Are there any ultra rares?</summary>
            <div class="faq__content">
              <p>YES. There are 10 gold rares – 6 carry gold skis and 4 carry a gold snowboard.</p>

            </div>
          </details>
          <details>
            <summary>Is the art hand drawn?</summary>
            <div class="faq__content">
              <p>Yes! No AI has been used in the creation of this project. We also cycled through many different artists before discovering Syndromea Art, who immediately understood the style and vision of the project and has done an incredible job</p>

            </div>
          </details>
          <details>
            <summary>Will there be a reveal?</summary>
            <div class="faq__content">
              <p>Yes, the art will be revealed 72 hours after completion of the mint</p>

            </div>
          </details>
          <details>
            <summary>Why should I mint?</summary>
            <div class="faq__content">
              <p>You will own the trademark to an awesome, high resolution piece of art that is free for you to do whatever you wish with (2000x2000)</p>
              <p>You will be a part of the first skiing NFT collection and community</p>
              <p>You will be entered into 3 different a drawings to win an Ikon Season Pass for the 2023-2024 season (or equivalent value in USDC). There will be 3 different snapshots – 1 for each of the 3 months following the mint</p>
              <p>You will be able to access the token gated discord in which you can chat with other snow sports enthusiasts and web3 degens, as well as vote on future project updates and perks</p>
              <p>Automatic whitelist for companion collection (if the community votes for a companion collection)</p>
              <p>Because why the hell not!</p>
             </div>
          </details>
         
        </div>
      </div>


    )
  }
}

export default FAQ;

