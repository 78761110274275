import React, { Component } from 'react';
import '../App.css';
import lukasio from '../assets/lukasio.jpg';
import artist from '../assets/artist.jpg';
import marketing from '../assets/marketing.jpg';
import appslk from '../assets/appslk.jpg';
import ikon from '../assets/ikon.jpg';
import epic from '../assets/epic.jpg';

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);


const appslkLink = () => {
  window.open("https://www.fiverr.com/appslkofficial");
}

class Team extends Component {

  async componentDidMount() {

  }

  render() {
    return (
      <div class="boxWrap2Team">

        <div class="glitch-wrapper">
          <div class="glitch" data-text="TEAM">TEAM</div>
        </div>
        <div class="teamSection">

          <div class="team1">
            <div class="manDiv"><img class="man" src={lukasio} /></div>
            <div class="tAndName">
              <div class="memName">Lukasio</div>

            </div>
            <div class="memNamePosition">Founder + Manager</div>
          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={artist} /></div>
            <div class="tAndName">
              <div class="memName">Syndromea Art Illustration</div>

            </div>
            <div class="memNamePosition"> Artist</div>

          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={marketing} /></div>
            <div class="tAndName">
              <div class="memName">Ahsan - NFTKingMaker</div>

            </div>
            <div class="memNamePosition">Marketing</div>

          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={appslk} /></div>
            <div class="tAndName">
              <div class="memName" onClick={appslkLink}><span class="link">Appslk Official</span></div>

            </div>
            <div class="memNamePosition">Website Design + Solidity Developer</div>

          </div>

        </div>
        <div class="glitch-wrapper">
          <div class="glitch" data-text="PARTNERS">PARTNERS</div>
        </div>
        <div class="teamSection">

          <div class="team1">
            <div class="manDiv"><img class="man2" src={ikon} /></div>
            <div class="tAndName">
              <div class="memName">Ikon Pass</div>
            </div>
          </div>

          <div class="team1">
            <div class="manDiv"><img class="man2" src={epic} /></div>
            <div class="tAndName">
              <div class="memName">Epic Pass</div>
            </div>


          </div>

        </div>
      </div>
    )
  }
}

export default Team;