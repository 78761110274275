import './App.css';
import React, { Component } from 'react';
import Story from "./components/story";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import os from './assets/opensea.png';
import Carousel from './components/carousel';
import Footer from './components/footer';
import discord from './assets/discord.png';
import nft from './assets/232.png';
import up from './assets/arrow-up.png';
import $ from "jquery";
import video from './assets/glowBall2.mp4';
import twitter from './assets/twitter.png';
import FAQ from './components/faq';
import Team from './components/team';
import Ticker from './components/ticker';
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

$(document).ready(function () {
	const stickyButton = $("#sticky-button");
	$(window).on("scroll", function () {
		if ($(this).scrollTop() > 200) {
			stickyButton.css("visibility", "visible");
		} else {
			stickyButton.css("visibility", "hidden");
		}
	});
});

const opensea = () => {
	window.open("https://opensea.io/collection/alpha-skier-club-2");
}

const tweet = () => {
	window.open("https://twitter.com/AlphaSkierClub");
}

const dis = () => {
	window.open("https://discord.gg/Ct4vMFVJEw");
}

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let onlyLeft;
let owner;
let publicSale;
let public_sale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;

let account2;
let mintAmount2 = 1;
let valueOfNFTs2 = 0;
let totalSupplyNFT2;
let onlyLeft2;
let owner2;
let publicSale2;
let public_sale_cost2;
let MAX_PER_WALLET2;
let public_mint_status2 = false;
let setEmail;
let email = [];
let emailAddress = [];
let wlCost;
let total_wl_limit;
let total_wl_count;
let wlMsg = "Free Mint Whitelist";
let FinalResult;
let wl_mint_status;


// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [

	"0xB1BE6557E40a1E1998C3283a66f9cBC3d6EDFbc4",

	//NEW ADDRESSES
	"0xF903EF51640EfD24c8a80b229CD3CCd2848e2D0C",
	"0x6906373374A8067F6844c209D771b5AD73C458af",
	"0xDEe5D4f282adE0364CeCEd1E7eD5b9eFE0658167",
	"0xBb0A75c6F2bAC16890d4571C4eC635115aD87ff4",
	"0x6447D3841fcb5f80357393cccC1cCDC4933b2D69",
	"0xC950d025B3cc82fCa6d61ce44dfD0Bbf7A53aA66",
	"0x0739Ad880C3727D246c1646480298e26462afe64",
	"0x5Ee7b4c33e40354843E6bA5477299Ea68Ac6f15A",
	"0xD628A948af39dC8b05F371bD9Bf2830bBFa4da1D",
	"0x8e08DF145A3636125f25DbB00c3a010700b2286D",
	"0xCB45A64cAC43bBcB343a4c9Ba928d4a488dC5a58",
	"0x6f1932e3ee94b363cc6b901103966013694f5c67",
	"0x6965c6Ec51f4941c5eF493bf933e56Fc1aD40aC3",
	"0x2a3912942CbAf93Bf2afb865c2cbbd72ACD8b39f",
	"0x534cD0622847b3E43c4dE6Ae8a3b92f090892656",
	"0xB1Aa768398C5098939A839C3fA3452cCea90a3D2",
	"0xaaFf52156897D3ea602DDd21CAE7F4533660a5ab",
	"0x7DEf92531dE8e95FDa813242FDC5008fdB5A1e18",
	"0xDE581da3eA8dbCEB516F2244fffAC0cc97E77c71",
	"0xbB4C685807eDE39EC5348d6aC895B56FE20bFd81",
	"0x8934594590B1AE3ae02131B8a073AEE581626003",
	"0x7b54A1299191CFC1d26Fc498f538C663E59CA2E5",
	"0xf97Bdce56590967E81CEc5F87096408910cD18F2",
	"0x2e6F04Bf537f949d4d4FD050C45A7bb64d46C4Fe",
	"0xBe75cBCc80F8f41A92B14B07227E7029B2AFA44F",
	"0xc7A7Ef6b80c84Ad0C92203a629d9b0f7778530f7",
	"0x6d1cd2e6836cc4fb36b0d4c2e04efc9e75cc1469",
	"0xa40DBf41d59d5d8eC241C8E23C43c3FA9C3ae552",
	"0x1AA1910198f0f45C53dea3DA2A06060f28e5FFeB",
	"0xd9dFB7FbdC8cC95E9A6456059d642A42610e3B8F",
	"0xFa08497D180bd8142365beA08F3EA90a3ac03316",
	"0xcdc7b472e4cd7d8ca1d2470ab665a9be1c2bb24b",
	"0xAaf3601BA8fF507237A324BEd12964809F8eCDB4",
	"0x6Bf30E2fc38bAc89edB9333Ea96b003A0b2046c4",
	"0x7b4E68Dd68993164923458355E60875263f09F56",
	"0x1aBAc8201EB5bd8885F497Ec5F0B131270DF4f3A",
	"0xFb11C7171542F60C17657399c738819d14F1Cd35",
	"0x29cc7A4eD9db7f4aF792b5B6267CB889f649d68f",
	"0x7A09EfF52425388EDAdf0Bf4fb27A8d0bE327A55",
	"0xA3a59D0730221c59E81EC80C4B76dE191B9de814",
	"0x85557Eb4b15039Cc3c333A4cc575Ea20fd8BeDE9",
	"0x0B868Eae04833e8416776A5d920EB1b4e2034928",
	"0x913E7d2ED3327E71A23807928de3Ca6a95a47D35",
	"0x3904E6ae4d527A2119B2dabFdf86E8DcbFF5901a",
	"0xCd1184Bf98bAAECd345582587CC95aB407347f55",
	"0x421819839Ec72F29A187D57D586D81bA979f68B8",
	"0x34F28efC03C95eE5619f22af0BA636354e78805C",
	"0x6dbcF58978DAB5681cE6396CC31bEb6B7157767c",
	"0x4bFD710bB89E499102f2D1493587F8846CfED079"
];

let whitelistAddresses2 = [];

// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = whitelistAddresses2.map(addr2 => keccak256(addr2));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const rootHash2 = merkleTree2.getRoot();
const rootHashHash2 = merkleTree2.getHexRoot();

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "main70Address",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_main70Address",
				"type": "address"
			}
		],
		"name": "setMain70Address",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_total_wl_limit",
				"type": "uint256"
			}
		],
		"name": "setTotal_wl_limit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_whitelistCost",
				"type": "uint256"
			}
		],
		"name": "setWhitelistCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "newWhitelistSigner",
				"type": "bytes32"
			}
		],
		"name": "setWhitelistSigner",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_wl_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setWl_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_wl_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_wl_count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_wl_limit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32[]",
				"name": "_proof",
				"type": "bytes32[]"
			},
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "whitelistMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistSigner",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "wlMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wl_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wl_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0xcd24e617BA7fe65976adCe6A242876847f66555e";
//const address = "0x8A81C0C59C12f05Ec1b53ED8e710982f455d75e0";
//const address = "0xC638A4E39d30Dd0Dfc76C4Ad58f615678A8F96d5";
//const address = "0x7532467cF152DD970D4a4D8f4fd8BA2203e25Ea0";

let contract;

let images = [
	"./assets/cover1.jpg",
	"./assets/cover2.jpg",
	"./assets/cover3.jpg",
	"./assets/cover4.jpg",
	"./assets/cover5.jpg"
];

let currentImageIndex = 0;

const contElement = $(".cont");


class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		nftMintingAmount: '1',
		totalValue: "8000000000000000",
		maxmint: '',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		_wl_mint_status: false,
		_wlMsg: "Free Mint Whitelist"

	}

	async componentDidMount() {
		if (localStorage?.getItem('isWalletConnected') === 'true') {
			try {
				const providerOptions = {
					walletconnect: {
						package: WalletConnectProvider, // required
						options: {
							infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
						}
					},
					/*coinbasewallet: {
						package: CoinbaseWalletSDK, // Required
						options: {
							appName: "Aterium Universe", // Required
							infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
							rpc: "", // Optional if `infuraId` is provided; otherwise it's required
							chainId: 1, // Optional. It defaults to 1 if not provided
							darkMode: true // Optional. Use dark theme, defaults to false
						}
					}*/
				};

				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});



				const provider = await web3Modal.connect();

				//  Enable session (triggers QR Code modal)
				await provider.enable();

				const web3 = new Web3(provider);
				console.log("provider : " + provider);
				// Subscribe to accounts change
				provider.on("accountsChanged", (accounts) => {
					console.log(accounts);
				});

				// Subscribe to chainId change
				provider.on("chainChanged", (chainId) => {
					console.log(chainId);
				});

				// Subscribe to provider connection
				provider.on("connect", (info) => {
					console.log(info);
					console.log("I'm LOGGED IN");
				});

				// Subscribe to provider disconnection
				provider.on("disconnect", (error) => {
					console.log(error);
				});

				// test if wallet is connected
				if (web3Modal.cachedProvider) {
					// connected now you can get accounts
					console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
					console.log("provider :" + provider);

					const accounts = await web3.eth.getAccounts();

					account = accounts[0];
					this.setState({ walletAddress: account });

					contract = new web3.eth.Contract(ABI, address);
					console.log("contract :" + contract);

					if (provider) {


						//	(async () => {

						if (web3Modal.cachedProvider != "walletconnect" /*&& web3Modal.cachedProvider != "coinbasewallet"*/) {

							const chainId = 1;

							if (window.ethereum.networkVersion !== chainId) {
								try {
									await window.ethereum.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{

													chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://etherscan.io'],

													/*chainName: 'Goerli Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],*/

													/*chainName: 'Rinkeby Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
												},
											],
										});
									}
								}
							}


							try {

								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });

								MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
								this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
								console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

								public_mint_status = await contract.methods.public_mint_status().call();
								if (public_mint_status) {
									this.setState({ _public_mint_status: "true" });
									console.log("public_mint_status :" + public_mint_status);
								} else {
									this.setState({ _public_mint_status: "false" });
									console.log("public_mint_status :" + public_mint_status);
								}

								wl_mint_status = await contract.methods.wl_mint_status().call();
								if (wl_mint_status) {
									this.setState({ _wl_mint_status: "true" });
									console.log("wl_mint_status :" + wl_mint_status);

								} else {
									this.setState({ _wl_mint_status: "false" });
									console.log("wl_mint_status :" + wl_mint_status);
								}


								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);
								//..........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 3000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;



									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 3000) {

										onlyLeft = 3000 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = MAX_PER_WALLET - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;



										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///................................///



								//..........................................................................//

							} catch (err) {
								console.log("err: " + err);

							}

						} else if (web3Modal.cachedProvider == "walletconnect") {

							const chainId = 1;

							if (WalletConnectProvider.networkVersion !== chainId) {
								try {
									await WalletConnectProvider.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://etherscan.io'],

													/*chainName: 'Goerli Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],*/

													/*chainName: 'Rinkeby Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://rinkeby.infura.io/v3/'],*/

													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
												},
											],
										});
									}
								}
							}


							try {

								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });

								MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
								this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
								console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

								public_mint_status = await contract.methods.public_mint_status().call();
								if (public_mint_status) {
									this.setState({ _public_mint_status: "true" });
									console.log("public_mint_status :" + public_mint_status);
								} else {
									this.setState({ _public_mint_status: "false" });
									console.log("public_mint_status :" + public_mint_status);
								}

								wl_mint_status = await contract.methods.wl_mint_status().call();
								if (wl_mint_status) {
									this.setState({ _wl_mint_status: "true" });
									console.log("wl_mint_status :" + wl_mint_status);

								} else {
									this.setState({ _wl_mint_status: "false" });
									console.log("wl_mint_status :" + wl_mint_status);
								}


								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);
								//..........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 3000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;



									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 3000) {

										onlyLeft = 3000 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = MAX_PER_WALLET - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;



										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///................................///



								//..........................................................................//

							} catch (err) {
								console.log("err: " + err);

							}


						}

						//})();

						//.....................................................................//

						// Legacy providers may only have ethereum.sendAsync
						const chainId = await provider.request({
							method: 'eth_chainId'
						})

					} else {

						// if the provider is not detected, detectEthereumProvider resolves to null
						console.error('Please install a Valid Wallet');
						alert('A valid provider could not be found!');

					}

				}
			} catch (ex) {
				console.log(ex);
			}
		} else {

			console.log("NOT CONNECTED");

		}
	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 3000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (totalSupplyNFT < 3000) {

				onlyLeft = MAX_PER_WALLET - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;



				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 3000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 3000) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;


				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],*/
											},
										],
									});
								}
							}
						}

						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							if (public_mint_status) {
								this.setState({ _public_mint_status: "true" });
								console.log("public_mint_status :" + public_mint_status);
							} else {
								this.setState({ _public_mint_status: "false" });
								console.log("public_mint_status :" + public_mint_status);
							}

							wl_mint_status = await contract.methods.wl_mint_status().call();
							if (wl_mint_status) {
								this.setState({ _wl_mint_status: "true" });
								console.log("wl_mint_status :" + wl_mint_status);

							} else {
								this.setState({ _wl_mint_status: "false" });
								console.log("wl_mint_status :" + wl_mint_status);
							}


							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);
							//..........................................................................//
							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 3000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 3000) {

									onlyLeft = 3000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;



									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],*/
											},
										],
									});
								}
							}
						}




						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							if (public_mint_status) {
								this.setState({ _public_mint_status: "true" });
								console.log("public_mint_status :" + public_mint_status);
							} else {
								this.setState({ _public_mint_status: "false" });
								console.log("public_mint_status :" + public_mint_status);
							}

							wl_mint_status = await contract.methods.wl_mint_status().call();
							if (wl_mint_status) {
								this.setState({ _wl_mint_status: "true" });
								console.log("wl_mint_status :" + wl_mint_status);

							} else {
								this.setState({ _wl_mint_status: "false" });
								console.log("wl_mint_status :" + wl_mint_status);
							}

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 3000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;




								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 3000) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;



									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 1 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					window.location.reload(true);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					window.location.reload(true);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}
			}
		} catch (err) {

			console.log(err);

		}
	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload(true);

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}
	}

	whitelistMint = async event => {
		event.preventDefault();


		//	console.log('Whitelist Merkle Tree\n', merkleTree.toString());
		console.log("Root Hash: ", rootHash);
		console.log("Root HashHash: ", rootHashHash);

		// ***** ***** ***** ***** ***** ***** ***** ***** // 

		// CLIENT-SIDE: Use `msg.sender` address to query and API that returns the merkle proof
		// required to derive the root hash of the Merkle Tree

		// ✅ Positive verification of address
		//const claimingAddress = leafNodes[0];
		//console.log("Claiming Address:"+ claimingAddress);
		// ❌ Change this address to get a `false` verification
		const claimingAddress = keccak256(account);

		// `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
		// be required to derive the Merkle Trees root hash.
		const hexProof = merkleTree.getHexProof(claimingAddress);
		console.log("HexProof:" + hexProof);

		// ✅ - ❌: Verify is claiming address is in the merkle tree or not.
		// This would be implemented in your Solidity Smart Contract
		console.log("Final result: " + merkleTree.verify(hexProof, claimingAddress, rootHash));
		FinalResult = merkleTree.verify(hexProof, claimingAddress, rootHash);
		this.setState({ _FinalResult: FinalResult });

		let wl_value = this.state._wlMintAmount * 1 * wlCost;
		let total_wl_mintings = publicSale * 1 + this.state._wlMintAmount * 1;
		console.log("total_wl_mintings:" + total_wl_mintings);
		console.log("publicSale:" + publicSale);
		console.log("wlMintAmount:" + this.state._wlMintAmount);

		if (FinalResult) {
			try {
				await contract.methods.whitelistMint(hexProof, 1).send({ gasLimit: 385000, from: account, value: 0 });
				window.location.reload(true);

			} catch (err) {
				console.log(err);
			}
		} else {
			wlMsg = "Not Eligible"
			this.setState({ _wlMsg: wlMsg });
		}


	}

	getEmail = async event => {
		event.preventDefault();
		for (let x = 0; x < 20; x++) {
			email[x] = await contract.methods.email(x).call();
			let hex_string = email[x].slice(2);
			emailAddress[x] = Buffer.from(hex_string, 'hex').toString('utf8');
			this.setState({ _email2: emailAddress });
		}
		console.log("emailAddress : " + emailAddress);

	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}



	render() {

		return (

			<div class="allWrap">
				<div class="light">

					<div class="vidCont">
						<video class="videoBg2" src={video} autobuffer autoPlay loop muted playsInline></video></div>
					<div class="snow"></div>
					<div class="snow"></div>

					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="snow"></div>
					<div class="cont">

						<Element name="up">

							{this.state._navbarOpen < 1 ?
								(<div class="miniBarMain">
									<div class="connect2">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect}>
												<button class="button-87">CONNECT WALLET</button>
											</form>) : (<form onSubmit={this.walletDisconnect}><button class="button-87" >
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

									</div>
									<div class="navbarMain"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
								</div>) :
								(<div class="miniBarMain">
									<div class="connect2">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect}>
												<button class="button-87">CONNECT WALLET</button>
											</form>) : (<form onSubmit={this.walletDisconnect}><button class="button-87" >
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

									</div>
									<div class="navbarMain"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
								</div>)}


							<div class="headers">

								<div class="h1">
									<div class="connect2Moile">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect}>
												<button class="button-87" >WALLET CONNECT</button>
											</form>) : (<form onSubmit={this.walletDisconnect}><button class="button-87" >
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

									</div>
									<div class="connect">
										<div ><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
										<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
										<div ><Link activeClass="" id="fontSize" to="carousel" spy={true} smooth={true} duration={550}>GALLERY</Link></div>
										<div ><Link activeClass="" id="fontSize" to="team" spy={true} smooth={true} duration={550}>TEAM</Link></div>
										<div ><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>

									</div>

									<div class="right">

										<div class="icons">

											<div class="discord"><img class="osPic" onClick={tweet} src={twitter} /></div>
											<div class="discord"><img class="osPic" onClick={opensea} src={os} /></div>
											<div class="discord"><img class="osPic" onClick={dis} src={discord} /></div>

										</div>
										<div class="connect2">
											{this.state.walletAddress === '' ?
												(<form onSubmit={this.walletConnect}>
													<button class="button-87" >WALLET CONNECT</button>
												</form>) : (<form onSubmit={this.walletDisconnect}><button class="button-87" >
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

										</div>


									</div>

								</div>


							</div>
							{this.state._navbarOpen > 0 ?
								(<div class="littleNav" data-aos="fade-left">

									<div ><Link activeClass="" id="fontSize2" to="about" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
									<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
									<div ><Link activeClass="" id="fontSize2" to="carousel" spy={true} smooth={true} duration={550}>GALLERY</Link></div>
									<div ><Link activeClass="" id="fontSize2" to="team" spy={true} smooth={true} duration={550}>TEAM</Link></div>
									<div ><Link activeClass="" id="fontSize2" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>


									<div class="icons">
										<img onClick={opensea} src={os} />
										<img onClick={tweet} src={twitter} />
										<img onClick={dis} src={discord} />
									</div>
								</div>) : null}
						</Element>


					</div>

					<Ticker />

					<Element name="carousel">
						<Carousel />
					</Element>

					<Element name="mint">

						<div class="mintMain">
							<div class="snow"></div>
							<div class="snow"></div>

							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="snow"></div>
							<div class="boxWrap2Mint">
								<div class="glitch-wrapper">
									<div class="glitch" data-text="MINT">MINT</div>
								</div>
								<div class="snow"></div>
								<div class="snow"></div>

								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="snow"></div>
								<div class="mintDiv" data-aos="zoom-in-up">

									<div class="pDiv"><img class="parrot" src={nft} /></div>
									<div class="mintCon">
										<div class="totalSupply">{this.state.totalSupply} / 3000</div>
										{this.state.totalSupply < 3000 ?
											(<div><div class="price"><div>Mint Price {this.state._cost / 1000000000000000000} Ξ</div></div>

												<div class="minting_count_button">
													<div class="center">
														<form onSubmit={this.onSubmitMinus}>
															<button class="btnfos-0-2" type="submit">-</button>
														</form>
													</div>

													<div>
														<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
													</div>

													<div class="center">
														<form onSubmit={this.onSubmitPlus}>
															<button class="btnfos-0-2" type="submit">+</button>
														</form>
													</div>
												</div>

												{this.state.walletAddress === '' ?

													(<div class="mintbuttondiv">
														<form onSubmit={this.walletConnect}>
															<button class="button-49">Connect</button>
														</form></div>) :

													(<div class="mintbuttondiv">
														{this.state._public_mint_status === "true" ? (

															<div>

																<form onSubmit={this.onSubmit2}>
																	<button class="button-49" type="submit">
																		Mint Here</button>
																</form>

															</div>
														) : (<form>
															<button class="button-49" type="submit">
																Coming Soon!</button>
														</form>)}
													</div>)}</div>) :
											(<div class="mintbuttondiv"><button class="button-49" type="submit">
												Sold Out!</button></div>)}
										<div>

											{this.state.statusError ? (
												<div class="errorMessage">
													<div >Sorry, something went wrong please try again later</div>
												</div>)
												: null}

											{this.state.statusLoading ? (
												<div class="loadingContainer">
													<div>
														<div class="loadingText">Minting your NFT</div>
													</div>
												</div>)
												: null}

											{this.state.success ? (

												<div><div class="successfully">MINTING SUCCESSFUL!</div>
												</div>)
												: null}

										</div>

									</div>
								</div>

								{this.state._wl_mint_status === "true" ? (
									<form onSubmit={this.whitelistMint}>
										<div class="placeAndWL">

											<button class="button-49" type="submit">
												{this.state._wlMsg}</button>
										</div>
									</form>) :
									(<form>
										<div class="placeAndWL">

											<button class="button-49" type="submit">
												Free Mint Coming Soon!</button>
										</div>
									</form>)}
							</div>

						</div>
					</Element>

					<Element name="welcome">
						<Story />
					</Element>

					<Element name="team">
						<Team />
					</Element>

					<FAQ />
					<Ticker />
					<Footer />

				</div>

				<Link id="sticky-button" to="up" spy={true} smooth={true} duration={550}> <img class="stickyBtn2" src={up} /> </Link>

			</div >)
	}
}

export default App;
