import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const tweet = () => {
	window.open("https://twitter.com/AlphaSkierClub");
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story2">

                <div class="glitch-wrapper">
                    <div class="glitch" data-text="ABOUT">ABOUT</div>
                </div>
                <div class="about">
                    <div class="storyCon2-2">
                        <p class="storyCon3" data-aos="fade-up" data-aos-duration="1500">Are you ready to take your love for skiing and NFT's to the next level? Look no further than the Alpha Skier Club - the premier skiing NFT collection and community in the game!</p>
                       <p class="storyCon3" data-aos="fade-up" data-aos-duration="1500">Being the FIRST skiing NFT collection, our mission is to unify the love of skiing and all snow sports with a cutting-edge web3 community and discord channel, a.k.a. "The Lodge". Although the foundation of this project is rooted in art, there are also some exciting perks that await all future members. Each NFT acts as a golden ticket to access the exclusive token gated ASC Discord, where we delve into and share stories, photos, and videos from our exhilarating powder days, as well as equipment preferences, new gear and fashionable attire, resort reviews, new tricks and terrain parks, and legendary ski stories. Although skiing and snowboarding are at the core of this project, we are equally invested in creating a space for chatting about the exciting world of NFT trading-with a focus on sharing the latest web3 news, alpha calls, and trading strategies.</p>
                       <p class="storyCon3" data-aos="fade-up" data-aos-duration="1500">Even if you don't ski but you enjoy the artwork and vision, you're more than welcome to be a part of this elite community. ASC is NOT CCO, which means you retain full ownership of your artwork, and you're free to use it however you wish, even if that means launching your own company and using your skier picture as the brand art!</p>
                       <p class="storyCon3" data-aos="fade-up" data-aos-duration="1500">To spice things up even more, for the first 3 months after the mint date, we will hold three different snapshots, and one lucky holder from each month will win an Ikon pass for the 2024 ski season or equivalent monetary value in USDC. Additionally, ASC holders will vote on further perks for the project. Some of our current ideas include an ASC NFT World (powered by "TryMetaFab") with a digital ski resort that only ASC holders can access, a playable skier avatar in Arcade Land's metaverse, and a whitelist to a companion collection. We welcome any additional ideas and want to create what's in the best interest of all ASC holders.</p>
                       <p class="storyCon3" data-aos="fade-up" data-aos-duration="1500">Each of the 3000 NFTs in our collection is meticulously hand-drawn, boasting well over 200 traits, and uploaded to IPFS at a remarkable high resolution of 2000x2000 pixels. Moreover, you'll have full ownership of your NFT image, allowing you to do whatever you wish with it! So strap on on your ski boots, fire up your metmask, and join us on a journey through the slopes of Web 3!</p>
                       <p class="storyCon3" data-aos="fade-up" data-aos-duration="1500">Mint Date: Spring 2023. Follow the official twitter for all announcements: <br/> <span class="linkNew" onClick={tweet}>https://twitter.com/AlphaSkierClub</span></p>
                    </div>

                </div>

            </div>
        )
    }
}

export default Story;