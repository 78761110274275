import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/logo.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">
 
                    <div class="socialMain2">
                        <img src={logo} />
                        <div class="copyright">Copyright © 2023 Alpha Skier Club. All Rights Reserved</div>
                    </div>
                    
            </div>
        )
    }
}

export default Footer;

